import { ref, computed } from 'vue'
import { useRouter } from 'vue-router/composables'
import { ApiResponseData, ApiResponseList } from '/~/types/api'
import { Address as AddressRawData } from '/~/types/api/addresses'
import api from '/~/core/api'
import emitter from '/~/core/emitter'
import Address from '/~/composables/addresses/core/Address'
import { useProvider } from '/~/composables/provider'

const addresses = ref<Address[]>([])
const addressesLoaded = ref(false)

const firstAddress = computed<Address | null>(() => addresses.value[0] || null)
const defaultAddress = computed(
  () => addresses.value.find((address) => address.default) || null
)
const userAddress = computed(() => defaultAddress.value || firstAddress.value)

const addressesWithoutDefault = computed(() => {
  return addresses.value.filter((address) => !address.default)
})

async function getAddresses() {
  try {
    const { data } = await api.get<ApiResponseList<AddressRawData>>(
      '/v3/addresses/?perPage=1000&page=0'
    )

    addresses.value = (data && 'items' in data ? data.items : []).map(
      (item) => new Address(item)
    )
  } catch (error: unknown) {
    addresses.value = []
    console.error(error)
  } finally {
    addressesLoaded.value = true
  }

  return addresses.value
}

async function addAddress(payload: AddressRawData) {
  const { data } = await api.post<ApiResponseData<AddressRawData>>(
    '/v3/addresses',
    payload
  )

  await getAddresses()
  emitter.emit('addresses:add', data)
  return data
}

async function updateAddress(payload: AddressRawData) {
  await api.put(`/v3/addresses/${payload.id}`, payload)
  return getAddresses()
}

async function removeAddress(payload: AddressRawData) {
  await api.delete(`/v3/addresses/${payload.id}`)
  emitter.emit('addresses:remove', payload)
  return getAddresses()
}

async function makeAddressDefault(payload: AddressRawData) {
  await api.put(`/v3/addresses/${payload.id}`, { default: true })
  return getAddresses()
}

async function checkAddresses() {
  if (addresses.value.length) {
    return
  }

  const { isCashrewardsProvider } = useProvider()
  const router = useRouter()

  await getAddresses()
  if (!addresses.value.length) {
    const to = isCashrewardsProvider.value
      ? { name: 'addresses-add' }
      : { hash: '#profile-add-address' }

    router.push(to)
  }
}

export function useAddresses() {
  return {
    addresses,
    firstAddress,
    defaultAddress,
    userAddress,
    addressesWithoutDefault,
    addressesLoaded,
    getAddresses,
    addAddress,
    updateAddress,
    removeAddress,
    makeAddressDefault,
    checkAddresses,
  }
}
